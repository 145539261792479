.coin-detail{
    padding-left: 120px;
    padding-top: 50px;
  }
  .time{
      font-weight: bold;
  }
  
  .coin-image img{
      margin-top: 60px;
  }
  
  .coin-name{
      font-weight: bold;
      font-size: 27px;
      margin-top: 50px;
  }
  .coin-price{
      font-weight: bold;
      font-size: 22px;
      margin-top: 10px;
  }
  .coin-profit{
      margin-top: 10px;
      margin-left: 7px;
  }
  
  .market-rank{
      font-weight: bold;
      font-size: 27px;
      margin-top: 20px;
      margin-left: 5px;
  }
  
  .coin-desc p{
      margin-top: 15px;
     
  }
  
  .coin-desc {
      width: 20rem;
  }
  .btn button{
    margin-top: 10px;
      height: 2rem;
      background-color: orange;
      border: none;
      width: 5rem;
      border-radius: 10px;
      font-weight: bold;
      margin-bottom: 30px;
      margin-left: 10px;
      cursor: pointer;
  }

  .coin-detail{
    display: flex;
    justify-content: space-evenly;
  }
  @media only screen and (max-width: 600px) {

     .coin-detail{
        display: flex;
        flex-direction: column;
        padding-left: 30px;
     }
  }