

.navbar{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: rgb(22, 20, 20);
    padding-bottom: 10px;
    position: fixed;
    width: 100%;
z-index: 99;    top: 0%;
}

.navbar ul{
    display: flex;
    list-style: none;

}
.navbar ul li a{
    margin-left: 15rem;
    text-decoration: none;
    color: white;
    font-size: 20px;

}

.logo{
    display: flex;
    align-items: center;
}
.logo h1{
    color: white;
}

@media only screen and (max-width: 600px) {
    .logo h1{
        font-size: 20px;
    }  
    .navbar{
        display: flex;
        justify-content: space-evenly;
        margin: 0;
        padding: 0;
        height: 4rem;
    }
    .navbar ul li a{
        margin-left: 10px;
        /* padding: 0; */
        font-size: 18px;
    }
    

}